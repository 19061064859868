<template>
  <!-- 想要商标 -->
  <div class="tmSale">
    <TbusinCom1></TbusinCom1>
    <!-- <div class="tmsTop">
      <div class="tstBox">
        <div class="tbLeft">
          <ul>
            <li class="tblLi" v-for="item in 9" :key="item">
              <p class="tl1">服装鞋帽</p>
              <dt class="tl2">
                <dl>服装</dl>
                <dl>鞋</dl>
                <dl>帽</dl>
                <dl>袜</dl>
              </dt>
              <div class="tlMore">
                <div class="tlmBox">
                  <p class="tm1">服装鞋帽</p>
                  <dl>
                    <dt class="tmDt" v-for="item in 9" :key="item">男装</dt>
                  </dl>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="tbRight">
          <div class="trTop">
            <img
              src="https://bce.bdstatic.com/tms-portal/img/banner.8ccea35.png"
              alt=""
              class="trtBg"
            />
            <img
              src="https://bce.bdstatic.com/tms-portal/img/banner_right.5d8fa7c.svg"
              alt=""
              class="trtImg"
            />
            <div class="trtP">
              <p class="tp1">海量商标 即买即用</p>
              <p class="tp2">海量优质商标，尽在威有智能云商标服务</p>
              <div class="tp3">立即咨询</div>
            </div>
          </div>
          <div class="trBottom">
            <ul>
              <li class="trbLi" v-for="item in 3" :key="item">
                <div class="tblBox">
                  <div class="tlText">
                    <p class="tlt1">热门商标</p>
                    <p class="tlt2">最新、最热商标</p>
                  </div>
                  <img
                    src="https://bce.bdstatic.com/tms-portal/img/nav_sub1.0a3ba5e.svg"
                    alt=""
                    class="tlIcon"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->
    <div class="tmsBox1">
      <div class="tb1Box">
        <div class="tb1Top">
          <p class="t11">海量热门商标数据</p>
          <p class="t12">海量优质商标数据，覆盖全行业，精选热门商标实时推荐</p>
        </div>
        <div class="tb1List">
          <ul>
            <li class="t1lLi" v-for="item in 45" :key="item">
              <div class="t1lP">
                <img
                  src="https://bce.bdstatic.com/tms-portal/img/01.f63d074.svg"
                  alt=""
                  class="t1Icon"
                />
                <p class="t1p">第{{ item }}类-化学原料</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tmsBox2">
      <div class="tb2Box">
        <div class="tb2Top">
          <p class="t21">精选优质商标</p>
          <p class="t22">精选商标推荐，即买即用，开启品牌升级之路</p>
        </div>
        <div class="tb2List">
          <ul>
            <li class="tb2Li" v-for="item in 5" :key="item">
              <div class="t2lBox">
                <div class="t2l1">
                  <img
                    src="https://aliimg.32.cn/tmimg/20220614/165515344547806.png"
                    alt=""
                    class="t21Img"
                  />
                </div>
                <p class="t2l2">威有13200元</p>
                <div class="t2l3">
                  <p class="t2lp">龟小慢</p>
                  <div class="t2lb">同名商标</div>
                </div>
                <p class="t2l4">
                  第29类-肉食蜜饯
                  干蔬菜，果冻，汤，鱼制商品，加工过的坚果，豆...
                </p>
              </div>
              <div class="t2lBtn">
                <div class="t2bs">提交需求</div>
                <div class="t2bs">立即想要</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tmsBox3">
      <div class="tb3Box">
        <div class="t3bTop">
          <div class="tb31">
            <p class="t3p">交易快报</p>
          </div>
          <div class="tb32">
            <ul>
              <li class="tb3Li" v-for="item in 6" :key="item">
                南京市 陈总 132****7309 以威有300000元 想要20类商标1个
                {{ item }}分钟前
              </li>
            </ul>
          </div>
          <div class="tb33">
            <img
              src="https://bce.bdstatic.com/tms-portal/img/news.563838a.svg"
              alt=""
              class="t33Img"
            />
          </div>
        </div>
        <div class="t3bList">
          <div class="t3lTop">
            <p class="t3t1">平台服务优势</p>
            <p class="t3t2">海量资源、全流程服务、交易安全放心</p>
          </div>
          <div class="t3lImgs">
            <ul>
              <li class="t3iLi" v-for="item in 5" :key="item">
                <div class="ti1">
                  <img
                    src="https://bce.bdstatic.com/tms-portal/img/advance1.1273446.png"
                    alt=""
                    class="tiImg"
                  />
                </div>
                <p class="ti2">商标资源更丰富</p>
                <p class="ti3">海量商标资源即买即用，平台优选来源真实可靠</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="tmsBox4">
      <div class="tb4Box">
        <div class="t4bTop">
          <p class="t41">商标交易流程</p>
          <p class="t42">标准化流程，专业可靠、品质有保证</p>
        </div>
        <div class="t4bList">
          <ul>
            <li class="t4lLi" v-for="item in 5" :key="item">
              <div class="t4l1">
                <img
                  src="https://bce.bdstatic.com/tms-portal/img/process1.6494422.svg"
                  alt=""
                  class="t4Icon"
                />
              </div>
              <p class="t4l2">挑选心仪商标</p>
              <div class="t4lx">
                <img
                  src="https://bce.bdstatic.com/tms-portal/img/arrow.e74ab4f.svg"
                  alt=""
                  class="t4xIcon"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="tmsBox5">
      <div class="tb5Box">
        <div class="tb5Left">
          <div class="tblBox">
            <p class="tbb1">没有找到您心仪的商标？</p>
            <p class="tbb2">
              提交需求后会有资深专家顾问帮您快速找到
              想要的商标，并为您提供优质的商标转让服务。
            </p>
            <p class="tbb3">海量商标，需求尽能满足！</p>
            <div class="tbb4">免费获取服务</div>
          </div>
        </div>
        <div class="tb5Right">
          <img
            src="https://bce.bdstatic.com/tms-portal/img/obtain_bg.2b31e7c.png"
            alt=""
            class="t5rImg"
          />
        </div>
      </div>
    </div>
    <div class="tmsBox6">
      <div class="tb6Box">
        <div class="t6bTop">
          <p class="t61">常见问题</p>
          <p class="t62">资深专家团队全程保驾护航，为客户提供更专业的服务</p>
        </div>
        <div class="t6bList">
          <ul>
            <li class="t6Li" v-for="item in 5" :key="item">
              <div class="t6n">{{ item }}</div>
              <div class="t6t">
                <p class="t6t1">想要商标多久可以拿到证书？</p>
                <p class="t6t2">
                  商标交易材料审核完成后会尽快递交商标局，
                  一般6-8个月左右，商标局会下发《核准转让证明》。
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="tb6Bottom">
        <ul>
          <li class="t6bLi" v-for="item in 4" :key="item">
            <div class="t6bBox">
              <div class="t6bT">
                <p class="t6p1">商标注册</p>
                <p class="t6p2">立即进入</p>
              </div>
              <div class="t6bI">
                <img
                  src="https://bce.bdstatic.com/tms-portal/img/tms_icon.a86f436.svg"
                  alt=""
                  class="t6iIcon"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TbusinCom1 from "./TbusinCom1.vue";

export default {
  name: "TmSale",
  components: {
    TbusinCom1,
  },
};
</script>

<style lang="less" scoped>
.tmSale {
  width: 100%;
  // margin-top: 87px;
  overflow: hidden;
  .tmsTop {
    width: 100%;
    height: 414px;
    margin-top: 30px;
    background: #eff3fb
      url("https://bce.bdstatic.com/tms-portal/img/nav_bg.b490d15.png")
      no-repeat bottom;
    .tstBox {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      box-shadow: 1px 1px 10px 1px gainsboro;
      border-radius: 10px;
      .tbLeft {
        width: 25%;
        height: 100%;
        float: left;
        box-shadow: 1px 1px 10px 1px gray;
        border-radius: 10px;
        position: relative;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .tblLi {
            width: 100%;
            height: 45px;
            padding-left: 20px;
            box-sizing: border-box;
            .tl1 {
              width: 56px;
              height: 45px;
              line-height: 45px;
              font-size: 14px;
              float: left;
            }
            .tl2 {
              width: 190px;
              height: 45px;
              float: left;
              margin-left: 5px;
              dl {
                min-width: 12px;
                height: 14px;
                font-size: 12px;
                color: gray;
                margin-left: 10px;
                margin-top: 15px;
                float: left;
                cursor: pointer;
              }
              dl:hover {
                color: #2468f2;
              }
            }
            .tlMore {
              width: 380px;
              height: 100%;
              background-color: white;
              position: absolute;
              top: 0;
              left: 285px;
              z-index: 9;
              border-radius: 10px;
              display: none;
              .tlmBox {
                width: 90%;
                height: 90%;
                margin: 0 auto;
                margin-top: 20px;
                .tm1 {
                  width: 100%;
                  height: 15px;
                  line-height: 15px;
                  font-size: 14px;
                }
                dl {
                  width: 100%;
                  min-height: 80px;
                  margin-top: 20px;
                  display: flex;
                  flex-wrap: wrap;
                  .tmDt {
                    min-width: 47px;
                    height: 24px;
                    line-height: 24px;
                    border-right: 1px solid gray;
                    color: gray;
                    text-align: center;
                    font-size: 12px;
                    cursor: pointer;
                  }
                  .tmDt:hover {
                    color: #2468f2;
                  }
                }
              }
            }
          }
          .tblLi:hover {
            box-shadow: 1px 1px 10px 1px gainsboro;
            .tlMore {
              display: block;
            }
          }
        }
      }
      .tbRight {
        width: 74%;
        height: 100%;
        margin-left: 1%;
        float: left;
        .trTop {
          width: 100%;
          height: 280px;

          position: relative;
          .trtBg {
            width: 100%;
            height: 100%;
            border-top-right-radius: 10px;
          }
          .trtImg {
            width: 244px;
            height: 188px;
            position: absolute;
            top: 40px;
            right: 130px;
          }
          .trtP {
            width: 40%;
            height: 140px;
            position: absolute;
            top: 70px;
            left: 30px;
            .tp1 {
              width: 100%;
              height: 30px;
              line-height: 30px;
              font-size: 28px;
            }
            .tp2 {
              width: 100%;
              height: 25px;
              line-height: 25px;
              font-size: 14px;
              color: gray;
              margin-top: 10px;
            }
            .tp3 {
              width: 120px;
              height: 30px;
              line-height: 30px;
              border-radius: 20px;
              color: white;
              background-color: #2468f2;
              text-align: center;
              font-size: 12px;
              margin-top: 30px;
            }
          }
        }

        .trBottom {
          width: 100%;
          height: 100px;
          margin-top: 20px;
          ul {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            .trbLi {
              width: 32%;
              height: 100px;
              border-color: white;
              box-shadow: 1px 1px 10px 1px gainsboro;
              border-radius: 10px;
              cursor: pointer;
              .tblBox {
                width: 90%;
                height: 60%;
                margin: 0 auto;
                margin-top: 8%;
                .tlText {
                  width: 100px;
                  height: 55px;
                  float: left;
                  .tlt1 {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 18px;
                  }
                  .tlt2 {
                    width: 100%;
                    height: 15px;
                    line-height: 15px;
                    color: gray;
                    font-size: 14px;
                  }
                }
                .tlIcon {
                  width: 50px;
                  height: 60px;
                  float: right;
                }
              }
            }
            .trbLi:hover {
              .tlText .tlt1 {
                color: #2468f2;
              }
            }
          }
        }
      }
    }
  }
  .tmsBox1 {
    width: 100%;
    height: 650px;
    .tb1Box {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .tb1Top {
        width: 100%;
        height: 80px;
        margin-top: 20px;
        text-align: center;
        .t11 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 28px;
        }
        .t12 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          color: gray;
        }
      }

      .tb1List {
        width: 100%;
        height: 430px;
        margin-top: 20px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          flex-wrap: wrap;
          .t1lLi {
            width: 20%;
            height: 40px;
            text-align: center;
            .t1lP {
              width: 60%;
              height: 16px;
              margin: 0 auto;
              margin-top: 10px;

              .t1Icon {
                width: 16px;
                height: 16px;
                float: left;
              }
              .t1p {
                width: 107px;
                height: 16px;
                font-size: 14px;
                margin-left: 5px;
                float: left;
                cursor: pointer;
              }
              .t1p:hover {
                color: #2468f2;
              }
            }
          }
        }
      }
    }
  }
  .tmsBox2 {
    width: 100%;
    height: 520px;
    .tb2Box {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .tb2Top {
        width: 100%;
        height: 80px;
        text-align: center;
        margin-top: 30px;
        .t21 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 28px;
        }
        .t22 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          color: gray;
        }
      }
      .tb2List {
        width: 100%;
        height: 300px;
        margin-top: 20px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          .tb2Li {
            width: 19%;
            height: 100%;
            box-shadow: 1px 1px 10px 1px gainsboro;
            border-radius: 10px;
            position: relative;
            .t2lBox {
              width: 90%;
              height: 90%;
              margin: 0 auto;
              .t2l1 {
                width: 100%;
                height: 150px;
                overflow: hidden;
                .t21Img {
                  width: 90%;
                  height: 90%;
                  margin: 0 auto;
                  margin-top: 10px;
                  display: block;
                }
              }
              .t2l2 {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 24px;
                color: #f27054;
                margin-top: 5px;
              }
              .t2l3 {
                width: 100%;
                height: 30px;
                margin-top: 5px;
                font-size: 12px;
                .t2lp {
                  width: 42px;
                  height: 30px;
                  line-height: 30px;
                  text-align: center;
                  font-size: 14px;
                  float: left;
                }
                .t2lb {
                  width: 64px;
                  height: 24px;
                  line-height: 24px;
                  text-align: center;
                  color: gray;
                  border: 1px solid gray;
                  border-radius: 5px;
                  float: right;
                }
                .t2lb:hover {
                  border-color: #2468f2;
                  color: #2468f2;
                }
              }
              .t2l4 {
                width: 100%;
                height: 52px;
                color: gray;
                font-size: 14px;
                margin-top: 5px;
              }
            }
            .t2lBtn {
              width: 100%;
              height: 38px;
              transition: 0.5s;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              background-color: white;
              opacity: 0;
              position: absolute;
              bottom: 1px;
              .t2bs {
                width: 50%;
                height: 38px;
                line-height: 38px;
                text-align: center;
                float: left;
                border-top: 1px solid gainsboro;
                cursor: pointer;
              }
              .t2bs:nth-child(1) {
                box-sizing: border-box;
                border-right: 1px solid gainsboro;
              }
              .t2bs:hover {
                background-color: #2468f2;
                color: white;
              }
            }
          }
          .tb2Li:hover {
            box-shadow: 1px 1px 10px 1px gray;
            .t2lBtn {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .tmsBox3 {
    width: 100%;
    height: 715px;
    .tb3Box {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .t3bTop {
        width: 100%;
        height: 150px;
        margin-top: 20px;
        box-shadow: 1px 1px 10px 1px gainsboro;
        border-radius: 10px;
        background: #fbfdff
          url(https://bce.bdstatic.com/tms-portal/img/news_bg.24d3d4a.png)
          no-repeat bottom;
        .tb31 {
          width: 25%;
          height: 100%;
          float: left;
          .t3p {
            width: 120px;
            height: 30px;
            line-height: 30px;
            font-size: 28px;
            text-align: center;
            margin: 0 auto;
            margin-top: 50px;
          }
        }
        .tb32 {
          width: 50%;
          height: 100%;
          float: left;
          ul {
            width: 480px;
            height: 60px;
            margin: 0 auto;
            margin-top: 40px;
            overflow: hidden;
            .tb3Li {
              width: 100%;
              height: 16px;
              line-height: 16px;
              text-align: center;
              font-size: 14px;
              color: gray;
              margin-bottom: 25px;
              transition: 0.5s;
              animation-name: gdT3l;
              animation-duration: 12s;
              animation-iteration-count: infinite;
            }
            @keyframes gdT3l {
              0% {
                transform: translateY(0);
              }
              25% {
                transform: translateY(-40px);
              }
              50% {
                transform: translateY(-80px);
              }
              75% {
                transform: translateY(-120px);
              }
              100% {
                transform: translateY(-160px);
              }
            }
          }
        }
        .tb33 {
          width: 25%;
          height: 100%;
          float: left;
          .t33Img {
            display: block;
            margin: 0 auto;
            margin-top: 30px;
          }
        }
      }
      .t3bList {
        width: 100%;
        height: 400px;
        margin-top: 60px;
        .t3lTop {
          width: 100%;
          height: 80px;
          line-height: 40px;
          text-align: center;
          .t3t1 {
            width: 100%;
            height: 40px;
            font-size: 28px;
          }
          .t3t2 {
            width: 100%;
            height: 40px;
            font-size: 14px;
            color: gray;
          }
        }
        .t3lImgs {
          width: 100%;
          height: 288px;
          margin-top: 30px;
          ul {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            .t3iLi {
              width: 19%;
              height: 100%;
              box-shadow: 1px 1px 10px 1px gainsboro;
              border-radius: 10px;
              .ti1 {
                width: 100%;
                height: 170px;
                overflow: hidden;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                .tiImg {
                  width: 100%;
                  height: 100%;
                }
              }
              .ti2 {
                width: 100%;
                height: 20px;
                line-height: 20px;
                font-size: 18px;
                margin-top: 20px;
                text-align: center;
              }
              .ti3 {
                width: 100%;
                height: 40px;
                font-size: 14px;
                text-align: center;
                color: gray;
                margin-top: 10px;
              }
            }
            .t3iLi:hover {
              box-shadow: 1px 1px 10px 1px gray;
            }
          }
        }
      }
    }
  }
  .tmsBox4 {
    width: 100%;
    height: 350px;
    background-color: rgba(239, 243, 251, 0.5);
    .tb4Box {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .t4bTop {
        width: 100%;
        height: 80px;
        text-align: center;
        margin-top: 30px;
        .t41 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 28px;
        }
        .t42 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          color: gray;
        }
      }
      .t4bList {
        width: 100%;
        height: 135px;
        margin-top: 40px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          .t4lLi {
            width: 90px;
            height: 135px;
            position: relative;
            .t4l1 {
              width: 90px;
              height: 90px;
              .t4Icon {
                width: 100%;
                height: 100%;
              }
            }
            .t4l2 {
              width: 90px;
              height: 40px;
              line-height: 40px;
              font-size: 15px;
            }
            .t4lx {
              width: 115px;
              height: 20px;
              border-top: 2px dashed #9c9c9c;
              position: absolute;
              top: 50px;
              left: 110px;
              .t4xIcon {
                width: 22px;
                height: 22px;
                position: absolute;
                top: -12px;
                left: 50px;
              }
            }
          }
          .t4lLi:nth-child(5) {
            .t4lx {
              display: none;
            }
          }
        }
      }
    }
  }
  .tmsBox5 {
    width: 100%;
    height: 560px;
    .tb5Box {
      width: 1280px;
      height: 100%;
      margin: 0 auto;
      .tb5Left {
        width: 40%;
        height: 100%;
        overflow: hidden;
        float: left;
        .tblBox {
          width: 100%;
          height: 225px;
          margin-top: 150px;
          .tbb1 {
            width: 100%;
            height: 40px;
            line-height: 40px;
            font-size: 28px;
          }
          .tbb2 {
            width: 100%;
            height: 35px;
            font-size: 16px;
            color: gray;
            margin-top: 15px;
          }
          .tbb3 {
            width: 100%;
            height: 18px;
            line-height: 18px;
            font-size: 16px;
            margin-top: 20px;
          }
          .tbb4 {
            width: 180px;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            text-align: center;
            color: white;
            background-color: #2468f2;
            border-radius: 25px;
            margin-top: 30px;
          }
        }
      }
      .tb5Right {
        width: 45%;
        height: 100%;
        float: right;
        .t5rImg {
          width: 90%;
          height: 320px;
          margin: 0 auto;
          margin-top: 100px;
        }
      }
    }
  }
  .tmsBox6 {
    width: 100%;
    height: 960px;
    background: rgba(239, 243, 251, 0.5);
    .tb6Box {
      width: 855px;
      height: 700px;
      margin: 0 auto;
      overflow: hidden;
      .t6bTop {
        width: 100%;
        height: 80px;
        text-align: center;
        margin-top: 30px;
        .t61 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 28px;
        }
        .t62 {
          width: 100%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          color: gray;
        }
      }
      .t6bList {
        width: 100%;
        height: 515px;
        margin-top: 20px;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .t6Li {
            width: 100%;
            height: 66px;
            .t6n {
              width: 28px;
              height: 28px;
              line-height: 28px;
              text-align: center;
              color: white;
              background-color: #2468f2;
              float: left;
              border-radius: 50%;
            }
            .t6t {
              width: 700px;
              height: 66px;
              float: left;
              margin-left: 10px;
              .t6t1 {
                width: 100%;
                height: 28px;
                line-height: 28px;
                font-size: 18px;
              }
              .t6t2 {
                width: 100%;
                height: 21px;
                line-height: 21px;
                font-size: 14px;
                color: gray;
              }
            }
          }
        }
      }
    }
    .tb6Bottom {
      width: 100%;
      height: 256px;
      overflow: hidden;
      background: url("https://bce.bdstatic.com/tms-portal/img/footer_bg.7c99bf7.png");
      background-size: 100% 100%;
      ul {
        width: 85%;
        height: 140px;
        margin: 0 auto;
        margin-top: 60px;
        display: flex;
        justify-content: space-around;
        .t6bLi {
          width: 23%;
          height: 100%;
          background-color: white;
          border-radius: 10px;
          box-shadow: 1px 1px 10px 1px gainsboro;
          cursor: pointer;
          .t6bBox {
            width: 90%;
            height: 100%;
            margin: 0 auto;
            .t6bT {
              width: 40%;
              height: 100%;
              float: left;
              .t6p1 {
                width: 100%;
                height: 40px;
                line-height: 40px;
                font-size: 24px;
                margin-top: 20px;
              }
              .t6p2 {
                width: 100%;
                height: 16px;
                line-height: 16px;
                font-size: 14px;
                color: gray;
                margin-top: 20px;
              }
            }
            .t6bI {
              width: 30%;
              height: 100%;
              float: right;
              .t6iIcon {
                width: 60px;
                height: 60px;
                display: block;
                margin-top: 30px;
              }
            }
          }
        }
        .t6bLi:hover {
          .t6p1 {
            color: #2468f2;
          }
        }
      }
    }
  }
}
</style>
