<template>
  <div class="tbusinCom1">
    <img
      src="https://cbs-public.cdn.bcebos.com/portal/20220617/banner.png"
      alt=""
      class="tt1Bg"
    />
    <div class="tc1Text">
      <p class="ttp1">云上公司注册<span>1元</span>起</p>
      <p class="ttp2">为创业者提供注册、记账报税、网站建设等一站式服务</p>
    </div>
    <div class="tc1List">
      <ul>
        <li class="t1lLi" v-for="item in 4" :key="item">
          <div class="t1lBox">
            <div class="tlb1">
              <img
                src="https://cbs-public.cdn.bcebos.com/portal/20220617/anchor-hot.png"
                alt=""
                class="tbImg"
              />
            </div>
            <div class="tlb2">
              <p class="tbp1">精选热门园区注册</p>
              <p class="tbp2">9大核心产业园，送地址、刻章</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TbusinCom1",
};
</script>

<style lang="less" scoped>
.tbusinCom1 {
  width: 100%;
  height: 550px;
  margin-top: 87px;
  position: relative;
  background-color: #e6f1ff;
  .tt1Bg {
    width: 100%;
    height: 70%;
    position: absolute;
    top: 0;
  }
  .tc1Text {
    width: 90%;
    height: 100px;
    position: absolute;
    top: 150px;
    left: 100px;
    .ttp1 {
      width: 100%;
      height: 48px;
      line-height: 48px;
      font-size: 48px;
      color: #122b61;
      span {
        color: #f33e3e;
      }
    }
    .ttp2 {
      width: 100%;
      height: 34px;
      line-height: 34px;
      font-size: 24px;
      margin-top: 15px;
    }
  }
  .tc1List {
    width: 90%;
    height: 88px;
    position: absolute;
    bottom: 60px;
    left: 75px;
    ul {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      .t1lLi {
        width: 23%;
        height: 88px;
        background: url("https://cbs-public.cdn.bcebos.com/portal/20220623/nav-item-bg.png")
          center;
        border-radius: 10px;
        box-shadow: 1px 1px 10px 1px gainsboro;
        cursor: pointer;
        transition: 0.5s;
        .t1lBox {
          width: 90%;
          height: 90%;
          margin-left: 15px;
          margin-top: 5px;
          .tlb1 {
            width: 20%;
            height: 100%;
            float: left;
            .tbImg {
              margin: 0 auto;
              margin-top: 20px;
              display: block;
              width: 36px;
              height: 36px;
            }
          }
          .tlb2 {
            width: 80%;
            height: 100%;
            float: left;
            margin-top: 10px;
            .tbp1 {
              width: 100%;
              height: 28px;
              font-size: 18px;
            }
            .tbp2 {
              width: 100%;
              height: 20px;
              font-size: 14px;
              color: gray;
            }
          }
        }
      }
      .t1lLi:hover {
        transform: translateY(-20px);
        box-shadow: 1px 1px 10px 1px gray;
        .tbp1 {
          color: #2469f3;
        }
      }
    }
  }
}
</style>
